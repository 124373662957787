import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页'
    },
    component: resolve => {
      require(['@/views/home/index.vue'], resolve)
    }
  },
  {
    path: '/how_it_works',
    name: 'HowItWorks',
    meta: {
      title: '如何运行'
    },
    component: resolve => {
      require(['@/views/howItWorks/index.vue'], resolve)
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    meta: {
      title: '常见问题'
    },
    component: resolve => {
      require(['@/views/faq/index.vue'], resolve)
    }
  },
  {
    path: '/contact_us',
    name: 'ContactUs',
    meta: {
      title: '联系我们'
    },
    component: resolve => {
      require(['@/views/contactUs/index.vue'], resolve)
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
